<template>
  <div>
    <skeleton-list :row="8" v-if="loading" />

    <el-table :data="items" v-else style="width: 100%">
      <el-table-column prop="date" label="DATE création" width="160">
        <template slot-scope="{ row }">
          <span>{{ row.created_at }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="numero" label="N° FACTURE" width="180">
        <template slot-scope="{ row }">
          <router-link :to="`/purchases/bills/` + row.bill_id">
            <span class="text-blue-500">{{ row.bill_no }}</span>
          </router-link>
        </template>
      </el-table-column>

      <el-table-column prop="status" align="center" label="STATUT" width="160">
        <template slot-scope="{ row }">
          <Status :name="row.status" />
        </template>
      </el-table-column>

      <el-table-column
        prop="vendor_first_name"
        label="FOURNISSEUR"
        min-width="125"
      >
        <template slot-scope="{ row }">
          <div class="flex flex-col">
            <span>{{ row.contact_organization }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        width="180"
        prop="total_all_taxe"
        label="TOTAL"
        align="right"
      >
        <template slot-scope="{ row }">
          <span>{{ row.total | moneyFilter }} {{user.currency_code}} </span>
        </template>
      </el-table-column>
      <el-table-column width="180" label="AVANCE" align="right">
        <template slot-scope="{ row }">
          <span>{{ row.avance | moneyFilter }} {{user.currency_code}}</span>
        </template>
      </el-table-column>
      <el-table-column width="100" label="A REGLER" align="right">
        <template slot-scope="{ row }">
          <span>{{ (row.total - row.avance) | moneyFilter }} </span>
        </template>
      </el-table-column>
      <el-table-column width="180" label="ACTION" align="right">
        <template slot-scope="{ row }">
          <cancel-bill
            :circle="true"
            type="BILL"
            :id="row.bill_id"
            :billNo="row.bill_no"
            @success="handleSuccess"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { toThousandFilter, parseTime, formatDate } from "@/Filters";
import Status from "@/components/Status";
import CancelBill from "../cancelBill.vue";
import {mapGetters} from "vuex";
export default {
  name: "BILL-PURCHASEORDER",
  filters: {
    moneyFilter: toThousandFilter,
    filterTime: parseTime,
    filterDate: formatDate,
  },
  components: { Status, CancelBill },
  props: {
    items: Array,
    loading: {
      type: Boolean,
      required: false,
    },
  },
    computed :{
        ...mapGetters(["user"]),
    },
  methods: {
    handleSuccess(e) {
      this.$emit("cancel", e);
    },
  },
};
</script>

<style lang="scss" scoped></style>
