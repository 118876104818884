<template>
  <el-button
    v-if="circle"
    size="mini"
    type="danger"
    icon="el-icon-delete"
    @click="handleCanceledBill"
    circle
  >
  </el-button>

  <el-button
    v-else
    type="danger"
    icon="el-icon-delete"
    @click="handleCanceledBill"
    >Annuler</el-button
  >
</template>

<script>
import { cancelBill } from "@/api/purchase";
export default {
  name: "CANCEL-BILL",
  props: {
    circle: Boolean,
    type: String,
    id: String,
    billNo: String,
  },

  methods: {
    handleCanceledBill() {
      const h = this.$createElement;
      this.$msgbox({
        title: "Facture N° " + this.billNo,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment annuler le document "),
          h("span", { style: "font-weight:bold" }, "N°:" + this.billNo),
        ]),
        showCancelButton: true,
        showClose: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            cancelBill(this.id, this.type)
              .then(() => {
                setTimeout(() => {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                  this.$emit("success", this.id);
                  this.$message({
                    type: "success",
                    message: "La facture d'achat a été annulée",
                  });
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
