<template>
  <div class="flex flex-col font-Inter px-10">
    <Loading v-if="loadingFull" />
    <div class="lg:flex lg:items-center lg:justify-between mb-3">
      <div class="flex-1 min-w-0 flex justify-between py-4">
        <div
          class="mt-1 flex flex-row justify-center items-center sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
        >
          <h2
            class="text-xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncate uppercase"
          >
            ORDRE DE COMMANDE N° {{ order.bill_no }}
          </h2>
        </div>
        <div>
          <el-button icon="el-icon-message" plain>Envoi email</el-button>
          <el-button
            icon="el-icon-printer"
            type="info"
            @click="exportInvoicePDF"
            >Imprimer</el-button
          >
          <el-button icon="el-icon-view" @click="handleViewPDF"
            >Aperçu</el-button
          >
          <el-button
            v-if="order.status === 'DRAFT'"
            type="primary"
            @click="handleConfirmed"
            >Confirmer l'ordre</el-button
          >
          <el-button
            v-if="order.status === 'IN_PROGRESS' || order.status === 'OPEND'"
            type="primary"
            icon="el-icon-s-order"
            @click="handleConverToBil"
            >Convertir en facture</el-button
          >
          <el-dropdown
            style="margin-left: 10px"
            @command="handleCommand"
            trigger="click"
          >
            <el-button>
              Action<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="copy">
                <i class="el-icon-document-copy"></i>
                Dupliquer
              </el-dropdown-item>
              <el-dropdown-item
                v-if="order.status === 'DRAFT' || order.status === 'OPEND'"
                command="update"
              >
                <i class="el-icon-edit"></i>
                Modifier
              </el-dropdown-item>
              <el-dropdown-item
                v-if="order.status === 'DRAFT' || order.status === 'OPEND'"
                command="cancel"
              >
                <i class="el-icon-delete"></i>
                Annuler
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="mb-10">
      <div class="flex flex-col mx-auto shadow bg-white p-5 border">
        <el-tabs v-model="tabName" @tab-click="handleTab" class="px-4">
          <el-tab-pane label="Aperçu" name="PURCHASE_ORDER">
            <div v-if="tabName === 'PURCHASE_ORDER'">
              <div class="flex justify-between items-start">
                <div class="flex flex-col w-1/2">
                  <h1
                    class="text-xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncate mb-5 uppercase"
                  >
                    {{ order.contact_name }}
                  </h1>
                  <div class="flex flex-col bg-gray-100 rounded-md w-2/3 p-3">
                    <div class="flex justify-between mb-2">
                      <span class="text-gray-500">N°</span>
                      <span class="text-blue-500">{{ order.contact_no }}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="text-gray-500">Téléphone</span>
                      <span>{{ order.phone }}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="text-gray-500">Email</span>
                      <span>{{ order.email }}</span>
                    </div>
                    <div class="flex justify-between">
                      <span class="text-gray-500">Adresse</span>
                      <span>{{ order.contact_street }}</span>
                    </div>
                    <div class="flex justify-between">
                      <span class="text-gray-500">Correspondant</span>
                      <span
                        >{{ order.contact_firstname }}
                        {{ order.contact_lastname }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="flex flex-col justify-end items-end w-1/2">
                  <div class="flex flex-col justify-end items-end my-2">
                    <div
                      class="text-3xl font-medium text-gray-900 sm:text-3xl sm:truncatemt-2"
                    >
                      {{ order.total | moneyFilter }} {{order.currency}}
                    </div>
                    <span class="text-base"> Montant TTC </span>
                  </div>
                  <div class="flex flex-col w-2/3 p-3">
                    <div class="flex justify-between mb-3">
                      <span class="text-gray-500">Date création :</span>
                      <span>{{ order.created_at }}</span>
                    </div>

                    <div class="flex justify-between mb-3">
                      <span class="text-gray-500">Date :</span>
                      <span>{{ order.bill_date }}</span>
                    </div>

                    <div class="flex justify-between mb-3">
                      <span class="text-gray-500">Date d'echéance :</span>
                      <span>{{ order.due_date }}</span>
                    </div>

                    <div class="flex justify-between mb-3">
                      <span class="text-gray-500">Statut</span>
                      <Status :name="order.status" />
                    </div>
                    <div class="flex justify-between mb-3">
                      <span class="text-gray-500">Modalité de paiement</span>
                      <div>
                        <span>{{ order.terms }}</span>
                      </div>
                    </div>
                    <div class="flex justify-between">
                      <span class="text-gray-500 mr-3">Ref.</span>
                      <p>{{ order.reference }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="customer-white">
                <el-table :data="order.items" style="width: 100%" class="mt-10">
                  <el-table-column prop="warehouse_code" label="C.E" width="80">
                    <template slot-scope="{ row }">
                      <span class="text-blue">{{ row.warehouse_code }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="article_no"
                    label="N° ARTICLE"
                    width="160"
                  >
                  </el-table-column>

                  <el-table-column
                    prop="article_name"
                    label="DÉSIGNATION"
                    min-width="250"
                  >
                    <template slot-scope="{ row }">
                      <div class="flex flex-col justify-start">
                        <div class="mb-1">{{ row.article_name }}</div>

                        <p class="text-gray-400">{{ row.description }}</p>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="quantity"
                    align="center"
                    label="QTÉ COMMANDÉE"
                    width="150"
                  >
                    <template slot-scope="{ row }">
                      <span>{{ row.quantity }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="quantity"
                    align="center"
                    label="QTÉ FACTURÉE"
                    width="150"
                  >
                    <template slot-scope="{ row }">
                      <span>{{ row.quantity_bill }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="right" label="PRIX" width="140">
                    <template slot-scope="{ row }">
                      <span>{{ row.price | moneyFilter }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="REMISE" width="140">
                    <template slot-scope="{ row }">
                      <span>{{ row.percent_discount }}%</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="TAXE" width="120">
                    <template slot-scope="{ row }">
                      <span>{{ row.percent_tax }}%</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="price"
                    align="right"
                    label="TOTAL"
                    width="150"
                  >
                    <template slot-scope="{ row }">
                      <span>{{ row.total | moneyFilter }} </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div class="flex justify-between items-start mb-2 border-b py-8">
                <div class="flex flex-col">
                  <div class="flex justify-between">
                    <span class="text-gray-500">Note :</span>
                    <p>{{ order.comment }}</p>
                  </div>
                </div>

                <div class="flex flex-col w-1/4 justify-start">
                  <div class="flex justify-between">
                    <span>Total HT:</span>
                    <span> {{ order.total_wt | moneyFilter }} </span>
                  </div>
                  <div class="flex justify-between pt-2 mt-4 text-gray-500">
                    <span class="mr-4"
                      >Remise ({{ order.percent_discount }} %) :</span
                    >
                    <span>{{ order.discount | moneyFilter }} </span>
                  </div>
                  <div class="flex justify-between mt-4 text-gray-500">
                    <span class="mr-4">Taxe ({{ order.percent_tax }}%):</span>
                    <span>{{ order.total_tax | moneyFilter }} </span>
                  </div>
                  <div class="flex justify-between mt-4 text-gray-500">
                    <span class="mr-4">Autre coût:</span>
                    <span>{{ order.shipping_amount | moneyFilter }} </span>
                  </div>
                  <div
                    class="flex justify-between font-medium leading-7 text-gray-900 py-4 border-b"
                  >
                    <span>Total :</span>
                    <span>
                      {{ order.total | moneyFilter }}
                      {{ order.currency }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Facture d'achat" name="BILL">
            <bill
              :items="bills"
              :loading="loadingBillList"
              v-if="tabName === 'BILL'"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-dialog
        title="Convertir en facture"
        :visible.sync="dialogVisibleInvoice"
        width="65%"
        top="1%"
        :close-on-click-modal="false"
      >
        <div>
          <el-form
            :model="bill"
            :rules="rulesBill"
            ref="billForm"
            :label-position="labelPosition"
          >
            <div class="flex">
              <el-form-item prop="bill_date" class="mr-2" label="Date">
                <el-date-picker
                  v-model="bill.bill_date"
                  type="date"
                  placeholder="jj mmm aaaa"
                  format="dd MMM. yyyy"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                class="mr-2"
                prop="bill_no"
                label="N° Facture d'achat"
              >
                <el-input v-model="bill.bill_no"> </el-input>
              </el-form-item>

              <el-form-item
                prop="due_date"
                class="mr-2"
                label="Date d'écheance"
              >
                <el-date-picker
                  v-model="bill.due_date"
                  type="date"
                  placeholder="jj mmm aaaa"
                  format="dd MMM. yyyy"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item class="mr-2" label="Autre coût">
                <el-input-number
                  :controls="false"
                  v-model="bill.shipping_amount"
                  :min="0"
                />
              </el-form-item>
            </div>
            <div class="article-items mt-5">
              <el-table border :data="bill.items" style="width: 100%">
                <el-table-column prop="" label="N°ARTICLE" width="100">
                  <template slot-scope="scope">
                    <span class="text-blue">{{ scope.row.article_no }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="article_name"
                  label="DESIGNATION ARTICLE"
                  min-width="250"
                >
                  <template slot-scope="{ row }">
                    <div class="flex flex-col justify-start">
                      <div class="mb-1">{{ row.article_name }}</div>

                      <p class="text-gray-400">
                        {{ row.article_description }}
                      </p>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="QTE COMMANDÉE"
                  width="150"
                >
                  <template slot-scope="{ row }">
                    <div class="">
                      {{ row.order_quantity }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="QTE FACTURÉE"
                  width="150"
                >
                  <template slot-scope="{ row }">
                    <div class="">
                      {{ row.quantity_bill }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="right" label="QTE RESTANT" width="150">
                  <template slot-scope="{ row }">
                    <div class="article-item-input">
                      <el-input-number
                        size="medium"
                        :controls="false"
                        v-model="row.quantity"
                        :min="1"
                        :max="row.order_quantity - row.quantity_bill"
                      />
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form>
        </div>

        <div slot="footer" class="border-t pt-2 dialog-footer">
          <el-button @click="handleCloseDialogVisible">Annuler</el-button>
          <el-button
            type="primary"
            :loading="loadingBill"
            @click="converToBill('billForm')"
            >Enregistrer</el-button
          >
        </div>
      </el-dialog>
    </div>
    <!-- View PDF -->
    <PDFView :url="urlPDF" :visible="dialogViewPDF" @close="handleClose" />
  </div>
</template>

<script>
import {
  getBillByID,
  converPurchaseOrderToBill,
  getAllBillOrder,
  cancelBill,
  opendBill,
  copyBill,
} from "@/api/purchase";
import { exportOrderAchatPDF } from "@/api/export";
import { parseTime, formatDate } from "@/utils";
import { toThousandFilter } from "@/Filters";
import Status from "@/components/Status";
import Bill from "./bill.vue";
import Loading from "@/components/Loanding/Full";
import PDFView from "@/components/PDF/view";

export default {
  name: "PurchaseOrderFiche",
  filters: {
    filterTime: parseTime,
    filterDate: formatDate,
    moneyFilter: toThousandFilter,
  },
  components: { Status, Bill, Loading, PDFView },
  data() {
    return {
      dialogViewPDF: false,
      urlPDF: null,
      loadingFull: false,
      dialogPayment: false,
      labelPosition: "top",
      loadingBill: false,
      loadingBillList: false,
      dialogVisibleInvoice: false,
      tabName: "PURCHASE_ORDER",
      type: "PURCHASE_ORDER",
      bills: [],
      order: {
        percent_amount: 0,
        percent_taxe: 0,
        bill_id: "",
        bill_no: "",
        avance: 0,
        contact_first_name: "",
        contact_id: "",
        contact_last_name: "",
        contact_organisation_name: "",
        due_date: "",
        due_days: 0,
        reference: "",
        shipping_charge: 0,
        status: "",
        total: 0,
        terms: "",
        total_wt: 0,
        items: [],
      },
      bill: {
        order_id: this.$route.params && this.$route.params.id,
        bill_no: "",
        bill_date: formatDate(new Date()),
        shipping_amount: 0,
        due_date: "",
        note: "",
        terms: "",
        items: [],
      },
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
      },
      rulesBill: {
        bill_no: [
          {
            required: true,
            message: "Numéro de la facture est obligatoire",
            trigger: "blur",
          },
        ],
        bill_date: [
          {
            required: true,
            message: "Date est obligatoire",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {
    tabName(val) {
      this.$router.push(`${this.$route.path}?tab=${val}`);
    },
  },
  created() {
    const tab = this.$route.query.tab;
    if (tab) {
      this.tabName = tab;
    }
  },

  mounted() {
    this.fetchData();
    this.fetchDataBill();
  },
  methods: {
    async fetchData() {
      const id = this.$route.params && this.$route.params.id;
      this.loadingFull = true;
      await getBillByID(id, this.type)
        .then((res) => {
          setTimeout(() => {
            this.loadingFull = false;
            this.order = Object.assign({}, res.data);
          }, 1.5 * 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    resetBill() {
      this.bill = {
        order_id: this.$route.params && this.$route.params.id,
        bill_no: "",
        bill_date: formatDate(new Date()),
        shipping_amount: 0,
        due_date: "",
        note: "",
        terms: "",
        items: [],
      };
    },
    handleConverToBil() {
      this.resetBill();
      this.order.items.forEach((el) => {
        if (el.quantity_bill < el.quantity) {
          console.log();
          this.bill.items.push({
            article_id: el.article_id,
            article_no: el.article_no,
            article_name: el.article_name,
            percent_discount: el.percent_discount,
            percent_tax: el.percent_tax,
            describe: el.describe,
            price: el.price,
            quantity_bill: el.quantity_bill,
            order_quantity: el.quantity,
            quantity: el.quantity - el.quantity_bill,
            warehouse_code: el.warehouse_code,
          });
        }
      });

      this.dialogVisibleInvoice = true;
    },
    handleCloseDialogVisible() {
      this.resetBill();
      this.dialogVisibleInvoice = false;
    },
    converToBill(billForm) {
      this.$refs[billForm].validate(async (valid) => {
        if (valid) {
          this.loadingBill = true;
          await converPurchaseOrderToBill(this.bill)
            .then(() => {
              setTimeout(() => {
                this.loadingBill = false;
                this.dialogVisibleInvoice = false;
                this.fetchData();
                this.$message({
                  message: "La facture créee avec succès.",
                  type: "success",
                });
              }, 1.5 * 1000);
            })
            .catch((err) => {
              this.loadingBill = false;
              if (err.data.status === 400) {
                this.$message({
                  message:
                    "Veuillez renseigner les informations obligatoire et les articles",
                  type: "warning",
                });
              } else if (err.data.status === 500) {
                this.$message({
                  message: "Erreur serveur",
                  type: "danger",
                });
              } else {
                this.$message({
                  message: err.data.message,
                  type: "warning",
                });
              }
            });
        }
      });
    },
    handleTab(obj) {
      switch (obj.name) {
        case "PURCHASE_ORDER":
          this.fetchData();
          break;
        case "BILL":
          this.fetchDataBill();
          break;
      }
    },
    async fetchDataBill() {
      const id = this.$route.params && this.$route.params.id;
      this.loadingBillList = true;
      await getAllBillOrder(id, "BILL").then((res) => {
        this.bills = res.data;
        setTimeout(() => {
          this.order = Object.assign({}, res.data);
          this.loadingBillList = false;
        }, 1.5 * 1000);
      });
    },
    handleCanceledBill() {
      const h = this.$createElement;
      this.$msgbox({
        title: "Order d'achat N° " + this.order.bill_no,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment annuler l'ordre d'achat ?"),
        ]),
        showCancelButton: true,
        showClose: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            cancelBill(this.order.bill_id, this.type)
              .then(() => {
                setTimeout(() => {
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);

                  this.order.status = "CANCELED";

                  this.$message({
                    type: "success",
                    message: "La facture d'achat a été annulée",
                  });
                  done();
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },

    handleCopyBill() {
      const h = this.$createElement;
      this.$msgbox({
        title: "Order d'achat N° " + this.order.bill_no,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment dupliquer l'ordre d'achat ?"),
        ]),
        showCancelButton: true,
        showClose: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            copyBill(this.order.bill_id, this.type)
              .then(() => {
                setTimeout(() => {
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);

                  this.order.status = "CANCELED";

                  this.$message({
                    type: "success",
                    message: "La facture d'achat a été dupliquée",
                  });
                  done();
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },

    handleConfirmed() {
      const h = this.$createElement;
      this.$msgbox({
        title: "Facture N° " + this.order.bill_no,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment confirmer l'ordre d'achat ?"),
        ]),
        showCancelButton: true,
        showClose: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            opendBill(this.order.bill_id, this.type)
              .then(() => {
                setTimeout(() => {
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);

                  this.order.status = "OPEND";

                  this.$message({
                    type: "success",
                    message: "L'ordre d'achat a été confirmé",
                  });
                  done();
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },

    handleCommand(e) {
      if (e === "cancel") {
        this.handleCanceledBill();
      }
      if (e === "update") {
        this.$router.push(`/purchases/order/update/${this.order.bill_id}`);
      }
      if (e === "copy") {
        this.handleCopyBill();
      }
    },

    exportInvoicePDF() {
      exportOrderAchatPDF(this.order.bill_id)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/pdf",
            })
          );
          let iframe = document.createElement("iframe"); //load content in an iframe to print later
          document.body.appendChild(iframe);

          iframe.style.display = "none";
          iframe.src = url;
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 1);
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleViewPDF() {
      this.dialogViewPDF = true;
      exportOrderAchatPDF(this.order.bill_id)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], { type: "application/pdf" })
          );
          this.urlPDF = url;
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "error",
          });
        });
    },
    handleClose() {
      this.dialogViewPDF = false;
    },
  },
};
</script>

<style></style>
